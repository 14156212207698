<template>
  <div class="term-option">
    <div
      v-if="!dataOptions.input"
      class="chat-term">
      <message :data="data" />
      <div
        ref="cardTerm"
        class="card-term">
        <v-card class="box-card">
          <v-card-text>
            <loading
              v-if="loadingTemplate < 1"
              class="absolute-center" />
            <pdf
              :src="urlTemplate"
              class="term-pdf" />
          </v-card-text>
        </v-card>

        <p class="ml-5 my-2">
          <a
            href=""
            class="accent--text"
            @click.prevent="openModal">Visualizar Termo</a>
        </p>
      </div>

      <modal
        :show="showModal"
        hide-actions
        @close="closeModal">
        <div slot="content">
          <pdf
            :src="urlTemplate"
            class="term-pdf" />
        </div>
      </modal>
    </div>

    <v-container
      v-else
      class="option-component">
      <div class="area-checkbox">
        <v-checkbox
          v-if="hasAccept"
          v-model="accept"
          :label="dataOptions.checkbox.label"
          @change="changeTerm" />
      </div>

      <v-btn
        v-for="(option, index) in dataOptions.options"
        :ref="`btn${index + 1}`"
        :key="index"
        :color="option.style"
        :disabled="option.disabled"
        class="option-btn"
        depressed
        dark
        @click="send(index, option.value)">
        {{ option.label }}
      </v-btn>
    </v-container>
  </div>
</template>

<script>
  import Message from './Message'
  import chatUiMixin from '@/mixins/chat-ui'
  import Loading from '@/components/ui/Loading'
  import Modal from '@/components/ui/Modal'
  import PdfTemplateModels from '@/mixins/pdf-template-models'

  export default {
    components: { Message, Modal, Loading },
    mixins: [ chatUiMixin, PdfTemplateModels ],
    data () {
      return {
        accept: true,
        urlTemplate: '',
        loadingTemplate: 0,
        showModal: false
      }
    },
    computed: {
      dataOptions () {
        return this.data
      },
      hasAccept () {
        if (this.data.checkbox) { this.accept = false }
        return this.data.checkbox
      }
    },
    watch: {
      data () {
        // setTimeout(() => {
        //   this.startOptionsAnimation()
        // }, 500)
      }
    },
    beforeMount () {
      this.previewDocument(this.dataOptions.agree)
    },
    mounted () {
      this.scaleAnimation()
    },
    methods: {
      send (index, value) {
        let data = {
          id: this.data.id,
          value
        }
        this.endOptionsAnimation(index, data)
      },
      async startOptionsAnimation () {
        // let children = this.$el.children[0].children
        // let children = this.$refs.buttons
        let children = '.term-option .option-btn'
        // let listTypes = [...children]
        // let listTypes = [this.$refs.btn1, this.$refs.btn2]
        let listTypes = [children]

        await listTypes.map((elem, index) => {
          let TIMELINE = this.anime.timeline({
            delay: 200 * (index + 1)
          })

          TIMELINE
            .add({
              targets: elem,
              duration: 500,
              opacity: [0, 1],
              scale: [0, 1]
            })
        })

        await this.anime({
          targets: '.area-checkbox .checkbox',
          opacity: [0, 1]
        })
      },
      async endOptionsAnimation (index, option) {
        // let children = this.$el.children[0].children
        // let listTypes = [...children]
        let children = '.term-option .option-btn'
        let listTypes = [children]
        let removedChild = listTypes[index]

        listTypes.splice(index, 1)
        listTypes.unshift(removedChild)

        await listTypes.map((elem, index) => {
          let TIMELINE = this.anime.timeline({
            delay: 100 * index
          })

          TIMELINE
            .add({
              targets: elem,
              opacity: [1, 0],
              scale: [1, 0]
            })
        })

        await this.anime({
          targets: '.area-checkbox .checkbox',
          opacity: [1, 0]
        })

        this.$emit('send', option)
      },
      changeTerm (isChecked) {
        this.dataOptions.options[0].disabled = !isChecked
      },
      loadTemplate (status) {
        this.loadingTemplate = status
      },
      previewDocument (isAgree) {
        this.loadingTemplate = 0
        isAgree ? this.agree() : this.disagree()
      },
      openModal () {
        this.showModal = true
      },
      closeModal () {
        this.showModal = false
      },
      async scaleAnimation () {
        const cardTerm = this.$refs.cardTerm

        await this.anime({
          targets: cardTerm,
          easing: 'easeInOutQuad',
          scale: [
            { value: 0, duration: 100 },
            { value: 1, duration: 500 }
          ],
          opacity: [
            { value: 0, duration: 100 },
            { value: 1, duration: 200 }
          ]
        }).finished

        await this.startOptionsAnimation()
      }
    }
  }
</script>

<style lang="sass">
  @import '../../../assets/style/placeholder'

  .option-btn,
  .v-legend,
  .area-checkbox .v-checkbox
    opacity: 0

  // scoped class
  .option-component
    display: flex
    justify-content: center
    flex-wrap: wrap

    .v-legend
      top: 5px
      position: absolute
      text-align: center
      font-size: 16px
      font-weight: 400

    .v-input--checkbox
      left: calc(50% - 260px)
      top: 15px
      position: absolute

    .v-input--selection-controls
      margin-top: 0
      padding-top: 0

    .option-btn
      margin: 5px 5px 0

  .term-pdf
    width: 100%

  .chat-term
    .card-term
      opacity: 0
      padding-right: 30px
      transform: scale(0)

      .box-card
        width: 40%
        overflow-y: scroll

    .v-card
      margin-bottom: 20px
      max-height: 350px
      padding: 10px 0

    .v-card__text
      max-height: 350px
      overflow-y: auto
      @extend %scroll

      div:nth-child(2) > div
        max-height: 200px

  .term-option
    .option-btn
      top: 30px

  @media screen and (max-width: $max-mobile)

    .term-option
      .option-component
        // bottom: -15px
        bottom: 0

        .v-checkbox
          left: 10px
          top: 5px

          label
            font-size: 14px
            line-height: 1.4
            text-overflow: initial
            overflow: inherit
            white-space: initial
            // max-width: 75%

    .chat-term
      .v-card
        max-height: 250px

    .v-modal .v-card__text
      padding: 0!important

</style>
